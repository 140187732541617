
  import { Component, Vue, Watch } from 'vue-property-decorator'
  import SellerHeader from '@/components/Seller/SellerHeader.vue'
  import { getAllAssistants, getAssistantsExcelForBusiness } from '@/services/seller.service'
  import { idoDateToDateAndHours } from '@/helpers/date'
  import { userYear } from '@/helpers/date'
  import { getMyData } from '@/services/user.service'
  import _ from 'lodash'
  
  @Component({
    components: {
      SellerHeader
    }
  })
  export default class AllAssistants extends Vue {
    public filter = ''
    public totalPages = 1
    public page = 1
  
    public assistants: any[] = []
    public assistantsFiltered: any[] = []
  
    public skeletonCount = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    public loading = false
  
    // Se puede obtener el business_id desde el usuario autenticado o del localStorage
    public businessId: number = Number(localStorage.getItem('business_id'))

    public totalRecords: number = 0
  
    async getAssistants(page?: number, query?: string) {
    try {
        if (!query) this.loading = true;
        const { data, meta } = await getAllAssistants(this.businessId, page, 100, query);
        this.totalPages = Math.ceil(meta.total / 100);
        this.totalRecords = meta.total;
        this.assistants = data;
        this.applyFilters();
        this.loading = false;
    } catch (e) {
        this.$toast.error('Error al obtener los asistentes');
        this.loading = false;
    }
    }

    
    // Abre el modal de exportación
    openExportModal() {
      this.$bvModal.show('export-modal')
    }

    // Cancela la acción de exportación
    cancelExport() {
      this.$bvModal.hide('export-modal')
    }

    // Confirma la exportación y descarga el archivo
    async confirmExport() {
      this.$bvModal.hide('export-modal')
      const loader = this.$loading.show();
      try {
        await getAssistantsExcelForBusiness(this.businessId);
        loader.hide();
      } catch (error) {
        loader.hide();
        this.$toast.error('Error al exportar a Excel');
      }
    }
  
    applyFilters() {
      // Se puede agregar filtros adicionales si es necesario
      this.assistantsFiltered = _.orderBy(this.assistants, 'created_at', 'desc')
    }
  
    onFilterChange() {
      this.getAssistants(1, this.filter)
    }
  
    formatDate(isoDate: string) {
      return idoDateToDateAndHours(isoDate)
    }
  
    formatBirthday(birthday: string): string {
      const date = new Date(birthday)
      const formattedDate = new Intl.DateTimeFormat('es-ES', {
        day: '2-digit',
        month: 'long',
        year: 'numeric'
      }).format(date)
      const age = this.getUserAge(date)
      return `${formattedDate} (${age} años)`
    }
  
    getUserAge(date: Date) {
      return userYear(date)
    }
  
    getUserGender(gender: string) {
      if (gender === 'male') return 'Masculino'
      else if (gender === 'female') return 'Femenino'
      else return 'Otro'
    }
  
    async getAssistantsExcel() {
      const loader = this.$loading.show();
      try {
          await getAssistantsExcelForBusiness(this.businessId);
          loader.hide();
      } catch (error) {
          loader.hide();
          this.$toast.error('Error al exportar a Excel');
      }
    }

  
    @Watch('page')
    onPageChange(nextPage: number) {
      this.getAssistants(nextPage, this.filter)
    }
  
    async mounted() {
    try {
        // Obtén la data del usuario para obtener el business_id correcto
        const { data: user } = await getMyData();
        // Asegúrate de que business_id sea válido
        this.businessId = Number(user.business_id);
    } catch (error) {
        this.$toast.error('Error al obtener la información del usuario');
    }
    // Luego carga los asistentes usando el business_id correcto
    await this.getAssistants(1, this.filter);
    }
  }
  